
  import { defineComponent, onMounted, ref } from 'vue';
  import { Field, Form } from 'vee-validate';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { useRoute, useRouter } from 'vue-router';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useAbility } from '@casl/vue';
  import Swal from 'sweetalert2/dist/sweetalert2.js';
  import * as Yup from 'yup';
  import { HomeServicesObject } from '@/store/modules/Assets/HomeServicesModule';

  export default defineComponent({
    name: 'home-services-editing',
    components: {
      Form,
      Field,
    },
    async setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const router = useRouter();
      const route = useRoute();
      const submitButton = ref<HTMLButtonElement | null>(null);
      const loading = ref(false);
      const { can } = useAbility();
      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };
      let fileP;
      onMounted(() => {
        setCurrentPageBreadcrumbs(translate('homeServiceUpdating'), [
          translate('assets'),
        ]);
      });
      const { data } = await store.dispatch(
        Actions.GET_HOME_SERVICE,
        route.params.id
      );

      const goBack = () => {
        router.back();
      };

      const homeService = ref<HomeServicesObject>(data.data);

      const thumbnail = ref(homeService.value.icon);
      const thumbnailWeb = ref(homeService.value.webIcon);

      const previewImage = (e) => {
        const file = e.target.files[0];
        fileP = file;
        thumbnail.value = URL.createObjectURL(file);
      };
      const previewImageWeb = (e) => {
        const file = e.target.files[0];
        fileP = file;
        thumbnailWeb.value = URL.createObjectURL(file);
      };

      const homeServiceSchema = Yup.object().shape({
        titleEn: Yup.string().required(() => {
          translate('HOME_SERVICE_TYPE_IS_REQUIRED_FIELD');
        }),
      });
      const upload = async () => {
        debugger;
        let logoFormData;
        if (fileP) {
          logoFormData = new FormData();
          logoFormData.append('images', fileP);
        } else {
          return;
        }
        if (logoFormData) {
          await store.dispatch(Actions.UPLOAD_HOME_SERVICE_IMAGE, {
            data: logoFormData,
            homeServiceId: homeService.value.id,
          });
        }

        Swal.fire({
          text: translate('SUCCESSFULLY_UPDATED_HOME_SERVICE'),
          icon: 'success',
          buttonsStyling: false,
          confirmButtonText: translate('ok'),
          customClass: {
            confirmButton: 'btn btn-light-primary',
          },
        });
      };
      const uploadWeb = async () => {
        debugger;
        let logoFormData;
        if (fileP) {
          logoFormData = new FormData();
          logoFormData.append('images', fileP);
        } else {
          return;
        }
        if (logoFormData) {
          await store.dispatch(Actions.UPLOAD_HOME_SERVICE_IMAGE_WEB, {
            data: logoFormData,
            homeServiceId: homeService.value.id,
          });
        }

        Swal.fire({
          text: translate('SUCCESSFULLY_UPDATED_HOME_SERVICE'),
          icon: 'success',
          buttonsStyling: false,
          confirmButtonText: translate('ok'),
          customClass: {
            confirmButton: 'btn btn-light-primary',
          },
        });
      };
      const onSubmitUpdate = async (values) => {
        if (submitButton.value) {
          // eslint-disable-next-line
          submitButton.value!.disabled = true;
          submitButton.value.setAttribute('data-kt-indicator', 'on');
        }

        delete values.logo;
        await store.dispatch(Actions.UPDATE_HOME_SERVICE, {
          id: route.params.id,
          data: values,
        });
        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];

        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
          //Deactivate indicator
          submitButton.value?.removeAttribute('data-kt-indicator');
          // eslint-disable-next-line
          submitButton.value!.disabled = false;
        } else {
          // if (logoFormData) {
          //   await store.dispatch(Actions.UPLOAD_HOME_SERVICE_IMAGE, {
          //     data: logoFormData,
          //     homeServiceId: route.params.id,
          //   });
          // }

          Swal.fire({
            text: translate('SUCCESSFULLY_UPDATED_HOME_SERVICE'),
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: translate('ok'),
            customClass: {
              confirmButton: 'btn btn-light-primary',
            },
          }).then(() => {
            router.push({ name: 'home-services-listing' });
          });
        }
      };

      store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading-over');

      return {
        onSubmitUpdate,
        submitButton,
        translate,
        homeServiceSchema,
        goBack,
        can,
        homeService,
        loading,
        previewImage,
        thumbnail,
        upload,
        thumbnailWeb,
        uploadWeb,
        previewImageWeb,
      };
    },
  });
